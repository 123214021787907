<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>是各领域专家，也是良师益友</div>
          <div>探索金融科技前沿领域，怀揣梦想和努力前行</div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="d-flex flex-column justify-content-center">
        <div class="container-lg px-4 mt-5">
          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 pt-3">
            <div class="col square p-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3">
                  <img src="../../assets/img/join/join-01.png"/>
                </div>
                <div class="col-9">
                  <div class="fs-5 fw-bold">为自己拼搏，也成就彼此</div>
                  <div class="hr gradient-cyan"></div>
                  <div class="fs-6 text-muted">
                    加入这个群星璀璨的团队，与国际竞赛冠军并肩战斗，
                    享受一起手撕难题的时刻。这里有完备的职业培训和晋升体系，还有灵活的内部转岗机制，让我们把不可能变成可能。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square p-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3">
                  <img src="../../assets/img/join/join-02.png"/>
                </div>
                <div class="col-9">
                  <div class="fs-5 fw-bold">只为让你自由发展</div>
                  <div class="hr gradient-teal"></div>
                  <div class="fs-6 text-muted">
                    探索最前沿的研究突破，指挥计算集群里的千军万码，玩转最硬核的软硬件，还有和资深前辈的零距离交流。在开放包容的文化氛围下，野蛮生长。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square p-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3">
                  <img src="../../assets/img/join/join-03.png"/>
                </div>
                <div class="col-9">
                  <div class="fs-5 fw-bold">共同走向美好生活</div>
                  <div class="hr gradient-orange"></div>
                  <div class="fs-6 text-muted">
                    我们倡导健康和平衡的生活理念，不定期组织团体活动，让我们的情谊更深、信念更强、团队更协同。我们不只是同事，更是并肩成长的伙伴。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square p-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3">
                  <img src="../../assets/img/join/join-04.png"/>
                </div>
                <div class="col-9">
                  <div class="fs-5 fw-bold">福利关怀，幸福感满满</div>
                  <div class="hr gradient-default"></div>
                  <div class="fs-6 text-muted">
                    我们有极具竞争力的薪酬和激励体系，以及个性化的福利机制，给你足够的幸福感！Coding
                    以外需要操心的事，我们都努力为你做到最好。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-light pt-4">
        <div class="container-lg px-4 mt-5">
          <div class="row mt-5">
            <div class="col-md-4 row mt-5 me-2">
              <div class="d-flex flex-column ps-5 pe-3 ">
                <div class="fs-3 mb-4 fw-bold color-orange">寻找优秀的你</div>
                <div class="fs-5 lh-lg text-muted">成为我们团队的一员，你准备好了吗？在这里，我们迎接新想法，追求新突破，拥抱新变化。</div>
              </div>
            </div>
            <div class="col-md-8 mt-3">
              <div class="row row-cols-1 row-cols-md-2 pt-3">
                <div class="col row pb-5">
                  <div class="col-3">
                      <img src="../../assets/img/join/为客户创造价值.png" alt="思考">
                  </div>
                  <div class="col-9">
                    <div class="fs-5 fw-bold">为客户创造价值</div>
                      <div class="fs-6 text-muted">
                        你是否致力于为客户创造价值，为他们带来最大化的收益？
                      </div>
                  </div>
                </div>
                <div class="col row pb-5">
                  <div class="col-3">
                      <img src="../../assets/img/join/成为最好自己.png" alt="相信">
                  </div>
                  <div class="col-9">
                    <div class="fs-5 fw-bold">成为最好的自己</div>
                      <div class="fs-6 text-muted">
                        你是否想被业内最聪明的人包围，享受终身学习的快乐？
                      </div>
                  </div>
                </div>
                <div class="col row pb-5">
                  <div class="col-3">
                      <img src="../../assets/img/join/承担挑战性任务.png" alt="合作">
                  </div>
                  <div class="col-9">
                    <div class="fs-5 fw-bold">承担挑战性任务</div>
                      <div class="fs-6 text-muted">
                        你是否接受基于强烈价值观的工程师文化，承担具有挑战性的任务？
                      </div>
                  </div>
                </div>
                <div class="col row pb-5">
                  <div class="col-3">
                      <img src="../../assets/img/join/准备好产生影响.png" alt="热情">
                  </div>
                  <div class="col-9">
                    <div class="fs-5 fw-bold">准备好产生影响</div>
                      <div class="fs-6 text-muted">
                        你是否渴望在多元化、快节奏、技术驱动和日益敏捷的工作环境中产生影响？
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="career">
        <div class="container-lg px-4 py-5 mb-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5">
            职业发展
            <div class="hr gradient-default"></div>
          </div>
          <div class="classification row gx-0">
            <div class="col-6 mask-container">
              <div class="image society">
                <div class="mask"></div>
              </div>

              <div class="text">
                <router-link
                  role="button"
                  to="/about/social"
                  style="border-width: 3px"
                  class="
                    btn btn-outline-light
                    text-decoration-none
                    stretched-link
                    fs-3
                  "
                  >社会招聘</router-link
                >
              </div>
            </div>
            <div class="col-6 mask-container">
              <div class="image campus">
                <div class="mask"></div>
              </div>

              <div class="text">
                <router-link
                  role="button"
                  to="/about/campus"
                  style="border-width: 3px"
                  class="
                    btn btn-outline-light
                    text-decoration-none
                    stretched-link
                    fs-3
                  "
                  >校园招聘</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-light">
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold gx-0 d-inline-block mb-4">
            应聘流程
            <div class="hr gradient-default"></div>
          </div>
          <div class="row gx-4">
            <div class="col-12 col-sm-4 text-center position-relative">
              <img
                src="../../assets/img/join/process-01.png"
                class="mt-3 process-icon"
                alt="非凸科技"
              />
              <div class="fs-4 my-3">投递岗位</div>
              <div class="d-flex flex-column justify-content-start h-100">
                <div class="text-muted">浏览岗位</div>
                <div class="text-muted">投递简历</div>
              </div>
              <div class="arrow d-none d-sm-block">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
            <div class="col-12 col-sm-4 text-center position-relative">
              <img
                src="../../assets/img/join/process-02.png"
                class="mt-3 process-icon"
                alt="非凸科技"
              />
              <div class="fs-4 my-3">进行面试</div>
              <div class="d-flex flex-column justify-content-start h-100">
                <div class="text-muted">面试邀约</div>
                <div class="text-muted">专业初试（因岗位而异）</div>
                <div class="text-muted">面试（2-3次）</div>
              </div>
              <div class="arrow d-none d-sm-block">
                <i class="fas fa-chevron-right"></i>
              </div>
            </div>
            <div class="col-12 col-sm-4 text-center position-relative">
              <img
                src="../../assets/img/join/process-03.png"
                class="mt-3 process-icon"
                alt="非凸科技"
              />
              <div class="fs-4 my-3">加入非凸</div>
              <div class="d-flex flex-column justify-content-start h-100">
                <div class="text-muted">接受offer</div>
                <div class="text-muted">背景调查</div>
                <div class="text-muted">加入非凸</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- section id="livestream" >
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            分享会
            <div class="hr gradient-default"></div>
          </div>
          <swiper ref="swiper" class="swiper" :options="livestreamSwiperOption">
            <swiper-slide>
              <div class="row gy-5">
                <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-06.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV19W4y147KE/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-1 px-5 lh-lg livestream">
                  <div>分享主题：Rust在算法交易中的实际应用与积极效应</div>
                  <div>分享时间：2022.11.06 9:00-17:00</div>
                  <div>分享人：乔丹</div>
                  <div class="mb-4">主办方：中泰证券 XTP</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV19W4y147KE/?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-06.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="row gy-5">
                <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-05.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV18e4y1h7GJ/?vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-1 px-5 lh-lg livestream">
                  <div>直播主题：量化投资中的机器学习</div>
                  <div>直播时间：2022.09.08 18:00-20:00</div>
                  <div>分享人：李佐凡</div>
                  <div class="mb-4">直播支持：复旦大学管理学院</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV18e4y1h7GJ/?vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-05.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="row gy-5">
                <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-04.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1sW4y117h1?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-1 px-5 lh-lg livestream">
                  <div>直播主题：Rust 助力量化高频交易</div>
                  <div>直播时间：2022.07.31 14:10-14:40</div>
                  <div>分享人：陆一洲</div>
                  <div class="mb-4">直播支持：Rust China Conf</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1sW4y117h1?spm_id_from=333.999.0.0&vd_source=48940ef7f313a1bdf0a465665bca355a"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-04.png"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
            </swiper-slide>
           <swiper-slide>
              <div class="row gy-5">
                <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-03.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://www.bilibili.com/video/BV1k5411R7S5/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-1 px-5 lh-lg livestream">
                  <div>直播主题：量化策略&算法研究专场</div>
                  <div>直播时间：2022.04.14 19:00-20:30</div>
                  <div>宣讲人：策略研究专家</div>
                  <div class="mb-4">校方站：复旦大学</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://www.bilibili.com/video/BV1k5411R7S5/"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-03.png"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="row gy-5">
                <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-02.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="https://meetings.feishu.cn/s/1hpmfge0tj5kz?src_type=3"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-1 px-5 lh-lg livestream">
                  <div>直播主题：Rust 如何为量化行业加速赋能？</div>
                  <div>直播时间：2022.03.06 15:00-16:00</div>
                  <div>宣讲人：朱为</div>
                  <div class="mb-4">直播支持：Rust 中文社区</div>
                  <a
                    role="button"
                    target="_blank"
                    href="https://meetings.feishu.cn/s/1hpmfge0tj5kz?src_type=3"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-02.jpg"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="row gy-5">
                <div
                  class="col-md-6 position-relative"
                  style="max-height: 300px"
                >
                  <img
                    src="../../assets/img/join/live-stream-banner-01.jpg"
                    alt="非凸科技宣讲会"
                    class="w-100"
                  />
                  <a
                    href="http://live.csdn.net/room/csdnstudent/66vOk2ke"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="stretched-link"
                  ></a>
                </div>
                <div class="col-md-6 py-1 px-5 lh-lg livestream">
                  <div>直播主题：非凸科技-带你走进量化交易</div>
                  <div>直播时间：2022.02.11 19:00-20:00</div>
                  <div>宣讲人：朱为</div>
                  <div class="mb-4">直播支持：CSDN直播间</div>
                  <a
                    role="button"
                    target="_blank"
                    href="http://live.csdn.net/room/csdnstudent/66vOk2ke"
                    class="btn btn-primary me-3"
                    >直播回放</a
                  >
                  <a
                    href="/live-stream-poster-01.png"
                    target="_blank"
                    role="button"
                    class="btn btn-primary"
                    >了解更多</a
                  >
                </div>
              </div>
            </swiper-slide>
            <template v-slot:pagination>
              <div class="livestream-swiper-pagination text-center"></div>
            </template>
          </swiper>
        </div>
      </section-->

      <!-- section class="bg-light">
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            思考方式
            <div class="hr gradient-default"></div>
          </div>
          <div class="text-center">
            <div class="fw-bolder fs-3" style="letter-spacing: 5px">
              THINKING
            </div>
            <div
              style="
                width: 5rem;
                margin: 1rem auto;
                border-top: 2px solid black;
                border-bottom: 2px solid black;
              "
            ></div>
            <div class="text-muted lh-lg">
              <div>严谨研究，热衷创新</div>
              <div>探索技术，迭代进化，寻求突破</div>
              <div>扁平的组织架构，以结果为导向，给你自由空间</div>
              <div class="text-dark fs-5 fw-bold">
                我们愿意聆听每一个最真实的想法
              </div>
            </div>
            <div
              class="position-relative p-5 m-5 text-light d-none d-xl-block"
              style="margin-bottom: 6rem !important"
            >
              <div class="circle circle-1">
                <div style="top: 25%" class="position-relative">创新</div>
              </div>
              <div class="circle circle-2">
                <div style="top: 25%" class="position-relative">自由</div>
              </div>
              <div class="circle circle-3">
                <div style="top: 25%" class="position-relative">扁平</div>
              </div>
              <div class="circle circle-4">
                <div style="top: 25%" class="position-relative">严谨</div>
              </div>
              <div class="circle circle-5">
                <div style="top: 25%" class="position-relative">钻研</div>
              </div>
              <div class="circle circle-6">
                <div style="top: 25%" class="position-relative">协作</div>
              </div>
              <div class="circle circle-7"></div>
              <div class="circle circle-8"></div>
              <div class="circle circle-9"></div>
            </div>
          </div>
        </div>
      </section-->
      <section >
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            大家心声
            <div class="hr gradient-default"></div>
          </div>
          <swiper ref="swiper" class="swiper" :options="swiperOption">
            <swiper-slide
              ><div class="d-flex flex-column justify-content-around px-5">
                <div class="fs-5">
                  最幸福的事，莫过于坚持自己的热爱，入职公司两年多了，新想法总能第一时间得到同事的尊重和支持，让我动力十足，心怀感恩。
                </div>
                <div class="staff-container">
                  <div>
                    <img
                      class="staff-img border-color-1"
                      src="../../assets/img/staff/Dan.jpg"
                    />
                    <span class="mt-3 staff-title"> Dan </span>
                  </div>
                </div>
              </div></swiper-slide
            >
            <swiper-slide
              ><div class="d-flex flex-column justify-content-around px-5">
                <div class="fs-5">
                  这里有独特的工程师文化，没有层级，只拼技术。无论是想要与机器学习顶尖高手华山论剑，还是与量化行业大牛把酒言欢，非凸都是不二之选。
                </div>
                <div class="staff-container">
                  <div>
                    <img
                      class="staff-img border-color-2"
                      src="../../assets/img/staff/Jason.jpg"
                    />
                    <span class="mt-3 staff-title"> Jason </span>
                  </div>
                </div>
              </div></swiper-slide
            >
            <swiper-slide
              ><div class="d-flex flex-column justify-content-around px-5">
                <div class="fs-5">
                  量化科技公司不二之选。来这里，认识了很多业内大佬，成长很快；在这里，可以做自己想做的事，也不限制你的想象力。
                </div>
                <div class="staff-container">
                  <div>
                    <img
                      class="staff-img border-color-2"
                      src="../../assets/img/staff/Hota.jpg"
                    />
                    <span class="mt-3 staff-title"> Hota </span>
                  </div>
                </div>
              </div></swiper-slide
            >
            <swiper-slide
              ><div class="d-flex flex-column justify-content-around px-5">
                <div class="fs-5">
                  坚定的技术信仰、多元的企业文化、0障碍的平等沟通，赋予我强烈的归属感；舒适的工作环境以及学习提升机会，让我更好的找到追寻的目标。非凸可谓是Fintech人间理想！
                </div>
                <div class="staff-container">
                  <div>
                    <img
                      class="staff-img border-color-2"
                      src="../../assets/img/staff/Mavis.jpg"
                    />
                    <span class="mt-3 staff-title"> Mavis </span>
                  </div>
                </div>
              </div></swiper-slide
            >
            <swiper-slide
              ><div class="d-flex flex-column justify-content-around px-5">
                <div class="fs-5">
                  半山腰总是最挤的，你得去山顶看看。如果你有非凡的好奇心和强烈的探索欲以及一往无前的冲劲，在非凸你将收获一群志同道合的朋友。
                </div>
                <div class="staff-container">
                  <div>
                    <img
                      class="staff-img border-color-2"
                      src="../../assets/img/staff/Vicky.jpg"
                    />
                    <span class="mt-3 staff-title"> Vicky </span>
                  </div>
                </div>
              </div></swiper-slide
            >
            <swiper-slide
              ><div class="d-flex flex-column justify-content-around px-5">
                <div class="fs-5">
                  这里有熟谙量化算法的大牛，一流的工程师团队，扁平的同事关系，还有快速迭代的量化算法产品。更重要的是，可以做自己喜欢的事！
                </div>
                <div class="staff-container">
                  <div>
                    <img
                      class="staff-img border-color-2"
                      src="../../assets/img/staff/fMeow.jpg"
                    />
                    <span class="mt-3 staff-title"> fMeow </span>
                  </div>
                </div>
              </div></swiper-slide
            >
          </swiper>
        </div>
      </section>
      <!--section class="life-section">
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-2">
            非凸生活
            <div class="hr gradient-default"></div>
          </div>
          <div class="life-container row mb-5">
            <swiper
              ref="swiper"
              class="life-swiper col-lg-6"
              :options="lifeSwiperOption"
            >
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-1"></div>
              </swiper-slide>
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-2"></div>
              </swiper-slide>
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-5"></div>
              </swiper-slide>
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-6"></div>
              </swiper-slide>
            </swiper>
            <div class="swiper-text col-lg-4 col-lg-offset-2">
              <div class="fs-3 color-orange">体验特色福利</div>
              <div class="fs-5 mt-4">零食茶水，温暖贴心</div>
              <div class="fs-5 mt-2">弹性工作，彼此信任</div>
              <div class="fs-5 mt-2">在办公位上享受生活</div>
            </div>
          </div>
          <div class="life-container row my-5">
            <div class="swiper-text col-lg-4 col-lg-offset-2 d-none d-lg-block">
              <div
                class="float-right"
                style="text-align: left; width: fit-content; float: right"
              >
                <div class="fs-3 color-default">享受美好时光</div>
                <div class="fs-5 mt-4">定期团建，丰富生活</div>
                <div class="fs-5 mt-2">带薪休假，一应俱全</div>
                <div class="fs-5 mt-2">休息好才能更好地工作</div>
              </div>
            </div>
            <swiper
              ref="swiper"
              class="life-swiper col-lg-6"
              :options="lifeSwiperOption"
            >
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-3"></div>
              </swiper-slide>
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-4"></div>
              </swiper-slide>
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-7"></div>
              </swiper-slide>
              <swiper-slide class="ratio" style="--bs-aspect-ratio: 50%">
                <div class="life-image life-8"></div>
              </swiper-slide>
            </swiper>
            <div class="swiper-text d-lg-none">
              <div class="fs-3 color-default">享受美好时光</div>
              <div class="fs-5 mt-4">定期团建，丰富生活</div>
              <div class="fs-5 mt-2">带薪休假，一应俱全</div>
              <div class="fs-5 mt-2">休息好才能更好地工作</div>
            </div>
          </div>
        </div>
      </section-->
      <section class="bg-light">
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-3 ms-2">
            Q&A
            <div class="hr gradient-default"></div>
          </div>
          <div class="border-bottom py-3">
            <div class="fs-2 position-relative">
              公司理想的候选人是怎样的？
              <a
                class="stretched-link"
                role="button"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#qa3"
                aria-expanded="false"
              ></a>
            </div>
            <div id="qa3" class="collapse">
              <div class="border-0 py-4 fs-5">
                <ul>
                  <li>对量化行业有极强的兴趣，对计算机技术有热忱。</li>
                </ul>
              </div>
            </div>
          </div>
           <div class="border-bottom py-3">
            <div class="fs-2 position-relative">
              工程师常用哪种编程语言？
              <a
                class="stretched-link"
                role="button"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#qa4"
                aria-expanded="false"
              ></a>
            </div>
            <div id="qa4" class="collapse">
              <div class="border-0 py-4 fs-5">
                <ul>
                  <li>我们以 Rust 作为基础编程语言，相较于 C/C++ 等其他语言，在安全性与稳定性上可以得到更好的保障。</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="border-bottom py-3">
            <div class="fs-2 position-relative">
              如何应聘非凸职位?
              <a
                class="stretched-link"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#qa1"
                aria-expanded="false"
              ></a>
            </div>
            <div id="qa1" class="collapse">
              <div class="border-0 py-4 fs-5">
                <ul>
                  <li class="mb-2">
                    进入社会招聘/校园招聘-热招岗位，点击“简历投递”按钮。
                  </li>
                  <li class="mb-2">邮箱投递：recruit@ft.tech。</li>
                  <li class="mb-2">
                    邮件主题：应聘人+应聘岗位+地点+社招/校招。
                  </li>
                  <li class="mb-2">
                    扫码投递：<img
                      style="
                        width: 120px;
                        vertical-align: bottom;
                        margin-bottom: -10px;
                      "
                      src="../../assets/img/招聘求职.jpg"
                      alt="非凸科技"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="border-bottom py-3">
            <div class="fs-2 position-relative">
              如何内推？
              <a
                class="stretched-link"
                role="button"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#qa2"
                aria-expanded="false"
              ></a>
            </div>
            <div id="qa2" class="collapse">
              <div class="border-0 py-4 fs-5">
                <ul>
                  <li class="mb-2">
                    如果你有熟悉的朋友在非凸科技工作或是其合作伙伴，可以请对方协助通过内部推荐渠道，直接投递简历到岗位对应部门，优先进入面试环节。
                  </li>
                  <li class="mb-2">
                    简历提交格式：应聘人+应聘岗位+工作地点+推荐人姓名及所在部门。
                  </li>
                  <li class="mb-2">友情提示：请勿通过多种渠道重复投递简历。</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Join",
  data() {
    return {
      lifeSwiperOption: {
        spaceBetween: 30,
        allowTouchMove: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        effect: "fade",
        loop: true,
      },
      livestreamSwiperOption: {
        spaceBetween: 30,
        allowTouchMove: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".livestream-swiper-pagination",
          clickable: true,
        },
        loop: true,
      },
      swiperOption: {
        spaceBetween: 30,
        allowTouchMove: false,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        loop: true,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          720: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.$nextTick(() => {
      if (window.location.hash != "") {
        let anchor = this.$el.querySelector(window.location.hash);
        document.documentElement.scrollTop = anchor.offsetTop;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.container-lg {
  border-radius: 2rem;
}
.icon {
  font-size: 4rem;
}
.swipe-container {
  height: 130px;
  position: relative;
  transition: 1s transform cubic-bezier(0.2, 0, 0.2, 1),
    1s top cubic-bezier(0.2, 0, 0.2, 1);
}
.swipe {
  float: left;
}
.progress-upper {
  height: 8rem;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
}
.progress-lower {
  height: 8rem;
  border-bottom-left-radius: 3rem;
  border-bottom-right-radius: 3rem;
}
.staff-title {
  font-size: 1.25rem;
  color: #8f8f8f;
  font-style: italic;
}
.staff-container {
  margin-top: 2rem;
}
.staff-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.staff-img.border-color-1 {
  border-color: $--ft-primary-5;
}
.staff-img.border-color-2 {
  border-color: $teal;
}
.staff-img.border-color-3 {
  border-color: $cyan;
}
.staff-img.border-color-4 {
  border-color: $orange;
}
.carousel-item > div {
  height: 250px;
}
.carousel-item {
  text-indent: 2.5rem;
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/about/join_banner.jpg");
  background-position-y: 40%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.process-icon {
  width: 4rem;
  height: 4rem;
}
.arrow {
  position: absolute;
  right: 0;
  top: 50%;
}
.circle {
  border-radius: 50%;
  position: absolute;
  animation-name: bounce-in;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-duration: 1s;
  opacity: 0.8;
}
.circle-1 {
  height: 6rem;
  width: 6rem;
  left: 24%;
  top: 0;
  background-color: $--ft-primary-3;
  font-size: 1.75rem;
}
.circle-2 {
  height: 8rem;
  width: 8rem;
  left: 30%;
  top: 40%;
  background-color: #fd7e14;
  font-size: 2rem;
}
.circle-3 {
  height: 10rem;
  width: 10rem;
  left: 40%;
  top: 10%;
  background-color: #0dcaf0;
  font-size: 2.5rem;
}
.circle-4 {
  height: 8rem;
  width: 8rem;
  left: 53%;
  top: 10%;
  background-color: $--ft-primary-1;
  font-size: 2rem;
}
.circle-5 {
  height: 6rem;
  width: 6rem;
  left: 63%;
  top: 0%;
  background-color: #20c997;
  font-size: 1.5rem;
}
.circle-6 {
  height: 8rem;
  width: 8rem;
  left: 70%;
  top: 20%;
  background-color: #a2ccff;
  font-size: 2rem;
}
.circle-7 {
  height: 1.5rem;
  width: 1.5rem;
  left: 20%;
  bottom: 0;
  background-color: $--ft-primary-5;
}
.circle-8 {
  height: 2rem;
  width: 2rem;
  right: 15%;
  top: 20%;
  background-color: $--ft-primary-4;
}
.circle-9 {
  height: 1.5rem;
  width: 1.5rem;
  right: 20%;
  top: 0%;
  background-color: #0dcaf0;
  font-size: 2rem;
}

.life-section .square,
.life-section .color {
  height: 300px;
}
.life-section .square > div,
.life-section .color > div {
  background-size: cover;
  background-position: center;
  height: 100%;
}
.swiper-text {
  padding-top: 3rem;
}
.life-image {
  background-size: cover;
  height: 100%;
  // max-width: 600px;
}
.life-container {
  position: relative;
}
.life-1 {
  background-image: url("../../assets/img/join/life-01.jpg");
}
.life-2 {
  background-image: url("../../assets/img/join/life-02.jpg");
}
.life-3 {
  background-image: url("../../assets/img/join/life-03.jpg");
}
.life-4 {
  background-image: url("../../assets/img/join/life-04.jpg");
}
.life-5 {
  background-image: url("../../assets/img/join/life-05.jpg");
}
.life-6 {
  background-image: url("../../assets/img/join/life-06.jpg");
}
.life-7 {
  background-image: url("../../assets/img/join/life-07.jpg");
}
.life-8 {
  background-image: url("../../assets/img/join/life-08.jpg");
}
.classification {
  height: 400px;
}
.classification > div {
  transform: skew(10deg);
  overflow: hidden;
}
.classification .text {
  position: relative;
  top: 50%;
  left: 15%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  font-size: 1rem;
  // color: #fff;
  // border: 2px solid;
  // border-radius: 10px;
  transform: skew(-10deg);
}
.classification .text:hover {
  transform: scale(1.2) skew(-10deg);
  transition: all ease-out 0.3s;
}
.classification .text .title {
  padding: 5px;
}
.classification .image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
  transform: skewx(-10deg);
}
.classification .society {
  background-image: url("../../assets/img/join/society.jpg");
  transform-origin: bottom;
}
.classification .campus {
  background-image: url("../../assets/img/join/campus.jpg");
  transform-origin: top;
}

.mask {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  position: absolute;
  transition: all linear 0.3s;
}

.mask-container:hover .mask {
  opacity: 0.4;
}

.livestream {
  font-size: 1.5rem;
}
.livestream-swiper-pagination {
  margin-top:2rem;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.25rem;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .life-container > .swiper-text {
    text-align: center;
  }
  .classification * {
    transform: skewX(0) !important;
  }
  .classification > div {
    width: 100% !important;
    margin: 0 !important;
  }
  .livestream {
    font-size: 1rem;
  }
}
</style>
